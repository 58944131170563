<template>
  <div class="healthyHeadline">
      <div class="healthyHeadline-head">
          <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
      </div>
      <div class="healthyHeadline-content">
          <h5>宅在家中 如何正确用眼？</h5>
          <div class="p-one">
              疫情居家期间，看手机、看电视的时间增多，公众该如何正确用眼？又该如何防治儿童近视？上海市眼病防治中心主任医师邹海东为您科普正确用眼方式，并详细讲解居家眼健康的三条用眼知识。
          </div>
          <div class="p-img">
              <img src="../assets/images/healthyHeadline/u225.png" alt="">
          </div>
          <p>01</p>
          <p>切记用眼过度</p>
          <div class="p-two">
              长时间近距离用眼如玩手机、打游戏等，容易发生视频终端综合征，会出现眼酸、胀痛，引发干眼症，严重的会导致视力下降、神经衰弱和抵抗力下降等，因此建议成年人看手机和电脑20分钟后要休息，眺望远处至少20秒钟以上，适当的居家锻炼，可以改善干眼的症状。
          </div>
          <p style="padding-top:10px;">02</p>
          <p>近视没有特效药 家长重视最重要</p>
          <div class="p-three">
              如何防控儿童近视？近视没有特效药，家长重视最重要，要严格控制好孩子近距离用眼时间，年龄越小，用眼时间越短，阅读写字要确保光线适宜，努力做到“一拳一尺一寸”正确的坐姿，坚持上下午一天两次眼保健操，要注意做操前一定要先洗手。在通风免疫的同时，早晨、中午、下午不同的时间段尽量让孩子到阳台、窗口或者自家院子里放松远眺，接触户外自然光，每天沐浴阳光累计60分钟以上，这才能减少近视的发生。
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'healthyHeadline',
    data() {
        return {}
    },
    methods: {
        goHistotry() {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
.healthyHeadline {
    background: #fff;
    height: 100vh;
}
.healthyHeadline-head{
    height: 44px;
    line-height: 44px;
    background: rgba(105, 192, 255, 1);
}
.healthyHeadline-head .icon-fanhuijiantou {
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
.healthyHeadline-content {
    padding:15px 20px;
    box-sizing: border-box;
}
h5 {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.p-one {
    font-size: 14px;
    padding-bottom:20px;
}
.p-img {
    padding-bottom: 20px;
}
.p-img img{
    width: 100%;
    height: 143px;
}
p{
    font-size: 14px;
}
.p-two, .p-three {
    font-size: 14px;
}
</style>